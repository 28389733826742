
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { themes } from '../config/themes';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeMode } from '../redux/actions/themeActions';
import { CssBaseline } from '@mui/material';

const ThemeWrapper = ({ children }) => {

    const themeMode = useSelector(state => state.theme.mode);
    const dispatch = useDispatch();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [themConfigOverride, setThemeConfigOverride] = useState(null);

    useEffect(() => {
        if (!themeMode) {
            dispatch(setThemeMode(prefersDarkMode ? 'dark' : 'light'));
        }
    }, [prefersDarkMode])

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const themeName = queryParams.get('theme');
        const themeConfig = queryParams.get('themeConfig');
        if (themeName && themes[themeName]) {
            dispatch(setThemeMode(themeName));
        }

        if (themeConfig) {
            const parsedThemeConfig = JSON.parse(themeConfig);
            if (parsedThemeConfig) {
                let themeConfig = themes["white"];
                if (parsedThemeConfig?.primary) {
                    themeConfig.palette.primary.main = parsedThemeConfig?.primary;
                    themeConfig.palette.slotPicker.light = parsedThemeConfig?.primary;
                    themeConfig.palette.slotPicker.dark = parsedThemeConfig?.primary;
                    themeConfig.palette.slotPicker.main = parsedThemeConfig?.primary;
                }
                if (parsedThemeConfig?.background) {
                    themeConfig.palette.background.default = parsedThemeConfig?.background;
                }
                if (parsedThemeConfig?.slotPicker) {
                    themeConfig.palette.slotPicker.light = parsedThemeConfig?.slotPicker;
                    themeConfig.palette.slotPicker.dark = parsedThemeConfig?.slotPicker;
                    themeConfig.palette.slotPicker.main = parsedThemeConfig?.slotPicker;
                }
                themeConfig.components = {
                    MuiCssBaseline: {
                        styleOverrides: {
                            body: {
                                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                                    backgroundColor: "transparent",
                                    width: 3,
                                    marginLeft: 5,
                                    opacity: 0.3
                                },
                                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                                    borderRadius: 2,
                                    backgroundColor: "#959595", 
                                    minHeight: 24,
                                    opacity: 0.5
                                },
                                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                                    backgroundColor: parsedThemeConfig?.primary,
                                },
                                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                                    backgroundColor: parsedThemeConfig?.primary,
                                },
                                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                                    backgroundColor: parsedThemeConfig?.primary,
                                }
                            },
                        },
                    },
                }
                setThemeConfigOverride(themeConfig);
            }
        }
    }, [])

    const theme = React.useMemo(
        () => {
            let themeConfig = themConfigOverride ? themConfigOverride : themes[themeMode];
            return createTheme(themeConfig);
        },
        [themeMode, themConfigOverride],
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}

export default ThemeWrapper;
import React, { HTMLAttributes, KeyboardEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {
  Card,
  Dialog,
  DialogProps,
  Fade,
  Grow,
  IconButton,
  Slide,
  Typography,
  Zoom,
  Box,
} from '@mui/material';
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { VideoComponent } from '../Carousel/ImageCarousel';

const styles = {
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 64px)', // 64px = theme.spacing(8),
    minWidth: '70%',
    minHeight: '70%'
  },
  prevButton: {
    position: 'fixed',
    left: 8, // theme.spacing(2)
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#ffffff80',
    boxShadow: 7,
    zIndex: 9999
  },
  nextButton: {
    position: 'fixed',
    right: 8, // theme.spacing(2)
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#ffffff80',
    boxShadow: 7,
    zIndex: 9999
  },
  closeButton: {
    position: 'fixed',
    right: 8, // theme.spacing(2)
    top: "5%",
    transform: 'translateY(-50%)',
    backgroundColor: '#ffffff80',
    boxShadow: 7,
    zIndex: 9999
  },
  titleCard: {
    position: 'absolute',
    bottom: 32, // theme.spacing(4)
    left: '50%',
    transform: 'translateX(-50%)',
    padding: 16, // theme.spacing(2)
  },
  videoContainer: {
    backgroundColor: 'transparent',
    position: 'relative',
    width: '100%',
    height: '100%',
    minWidth: '50vw',
    minHeight: '50vh',
    maxWidth: "xl",
    maxHeight: "90vh",
    aspectRatio: '16/9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  infoText: {
    fontSize: 14,
    color: "#fff",
    textAlign: 'center',
    padding: '15px',
    opacity: 0.5,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    minWidth: '60vw',
    minHeight: '60vh',
    maxWidth: "xl",
    maxHeight: "90vh",
  },
  dialogue: {
    '& .MuiDialog-paper':
    {
      backgroundColor: "transparent",
      backgroundImage: 'none',
      margin: '8px', position: 'relative',
      overflow: 'hidden',
      boxShadow: 'none'
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  }
};

export const LightboxBase = ({
  items,
  TransitionComponent = Fade,
  onPrevious,
  onNext,
  activeItem,
  onKeyDown,
  onClose,
  ...props
}) => {

  const imageRef = useRef(null);

  const handlePreviousButtonClick = useCallback(() => onPrevious(), []);
  const handleNextButtonClick = useCallback(() => onNext(), []);
  const handleCloseButtonClick = useCallback(() => onClose(), []);

  const handleKeyDown = useCallback((e) => {
    switch (e.which) {
      case 37:
        onPrevious();
        onKeyDown && onKeyDown(e);
        break;
      case 39:
        onNext();
        onKeyDown && onKeyDown(e);
        break;
      default:
        onKeyDown && onKeyDown(e);
    }
  }, []);

  return (
    <Dialog maxWidth="xl" scroll="body" {...props} onKeyDown={handleKeyDown} sx={styles.dialogue}>
      {items.map(({ className, ...item }, index) => {
        if (index !== activeItem) {
          return null;
        }
        return (
          <TransitionComponent key={item.src} in={activeItem === index}>

            {item?.type == "video" ?
              <Box sx={styles.videoContainer}>
                <VideoComponent url={item.src} videoStyle={{}} isPaused={activeItem !== index} showControls />
              </Box>
              :
              <Box>
                <Typography sx={styles.infoText}>Double Tap to zoom</Typography>
                <TransformWrapper doubleClick={{ mode: "toggle" }}>
                  <TransformComponent>
                    <Box sx={styles.imageContainer}>
                      <img
                        ref={imageRef}
                        {...item}
                        style={{ ...styles.image }}
                      />
                    </Box>
                  </TransformComponent>
                </TransformWrapper>
                {item.title && (
                  <Card sx={styles.titleCard} variant="outlined">
                    <Typography variant="caption">{item.title}</Typography>
                  </Card>
                )}
              </Box>
            }
          </TransitionComponent>
        );
      })}
      {
        items?.length > 1 ?
          <>
            <IconButton sx={styles.prevButton} onClick={handlePreviousButtonClick}>
              <ChevronLeft />
            </IconButton>
            <IconButton sx={styles.nextButton} onClick={handleNextButtonClick} >
              <ChevronRight />
            </IconButton>
            <IconButton sx={styles.closeButton} onClick={handleCloseButtonClick} >
              <Close />
            </IconButton>
          </>
          : null
      }
    </Dialog >
  );
};

export const Lightbox = (props) => {
  const { images, initialImage, onPrevious, onNext, onClose = () => { }, activeImage, ...restProps } = props;

  const [selected, setSelected] = useState(initialImage || 0);

  const handlePreviousImageRequest = useCallback(() => {
    setSelected((sel) => (sel + images.length - 1) % images.length);
  }, [images]);

  const handleNextImageRequest = useCallback(() => {
    setSelected((sel) => (sel + 1) % images.length);
  }, [images]);

  useEffect(() => {
    if (!activeImage) {
      setSelected(initialImage || 0);
    }
  }, [initialImage])

  return (
    <LightboxBase
      items={images}
      onPrevious={onPrevious || handlePreviousImageRequest}
      onNext={onNext || handleNextImageRequest}
      activeItem={activeImage || selected}
      onClose={onClose}
      {...restProps}
    />
  );
};

export default Lightbox;

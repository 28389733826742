import React, { useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@emotion/react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useIsVisible } from '../../hooks/useIsVisible';
import Lightbox from '../LightBox/LightBox';

const ImageCarousel = ({ images = [], imageStyle = {}, videos = [] }) => {

    const theme = useTheme();
    const [currentIndex, setCurrentIndex] = useState(0);

    const [showLighBox, setShowLightBox] = useState(false);
    const [lightBoxImage, setLightBoxImage] = useState(0);
    const openLightBox = (imageUrl) => {
        setLightBoxImage(imageUrl);
        setShowLightBox(true);
    }
    const closeLightBox = () => {
        setLightBoxImage(null);
        setShowLightBox(false);
    }


    const handleChangeIndex = (index) => {
        setCurrentIndex(index);
    };

    const previewContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScrollLeft = () => {
        previewContainerRef.current.scrollTo({
            left: scrollPosition - 200,
            behavior: 'smooth',
        });
    };
    const handleScrollRight = () => {
        previewContainerRef.current.scrollTo({
            left: scrollPosition + 200,
            behavior: 'smooth',
        });
    };
    const handleScroll = (e) => {
        const position = e.target.scrollLeft;
        setScrollPosition(position);
    }

    const getThumbnail = (url) => {
        /* Currently Youtube Video thumbnails only */
        const thumb = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAABOklEQVR42mJ8fPjPwPAAwAB//+CpkQAAAABJRU5ErkJggg=="; //black/tranparent png 
        return thumb;
    }

    return (
        <Box style={{ position: 'relative' }}>
            <SwipeableViews
                index={currentIndex}
                onChangeIndex={handleChangeIndex}
                enableMouseEvents
            >
                {images.map((imageUrl, index) => (
                    <Box key={index} style={{ position: 'relative' }} onClick={() => openLightBox(imageUrl)}>
                        <img
                            key={index}
                            src={imageUrl}
                            alt={`service image ${index}`}
                            style={{ position: 'relative', zIndex: 10, width: '100%', objectFit: 'cover', height: 'auto', aspectRatio: 1, ...imageStyle }}
                        />
                        <Box
                            component="div"
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0, left: 0,
                                backgroundImage: `url(${imageUrl})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                filter: `blur(50px)`,
                                zIndex: 5
                            }}
                        />
                    </Box>
                ))}
                {videos.map((videoUrl, index) => (
                    <Box key={index} style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <VideoComponent url={videoUrl} videoStyle={imageStyle} isPaused={showLighBox || currentIndex !== (images.length + index)} />
                        <Box
                            component="div"
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0, left: 0,
                                backgroundImage: `url(${getThumbnail(videoUrl)})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                filter: `blur(50px)`,
                                zIndex: 5
                            }}
                        />
                    </Box>
                ))}
            </SwipeableViews>
            {((images.length + videos.length) > 1) ?
                <Box sx={{ position: "relative" }}>
                    <Box
                        ref={previewContainerRef}
                        onScroll={handleScroll}
                        sx={{
                            display: 'flex', gap: '0px', flexWrap: 'nowrap',
                            width: '100%', maxWidth: '100vw',
                            overflowX: 'auto', scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': { width: 0, height: 0, display: 'none' }
                        }}
                    >
                        {images.map((imageUrl, index) => (
                            <Box
                                key={index}
                                sx={{
                                    flex: '0 0 auto',
                                    height: 50, width: 50,
                                    margin: "5px",
                                    borderColor: currentIndex == index ? theme.palette?.primary?.main : "grey",
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    cursor: 'pointer'
                                }}
                                onMouseEnter={() => handleChangeIndex(index)}
                                onClick={() => openLightBox(imageUrl)}
                            >
                                <img
                                    key={index}
                                    src={imageUrl}
                                    alt={`service image ${index}`}
                                    style={{ width: '100%', objectFit: 'cover', height: 'auto', aspectRatio: 1 }}
                                />
                            </Box>
                        ))}
                        {videos.map((videoUrl, index) => (
                            <Box
                                key={index}
                                sx={{
                                    flex: '0 0 auto',
                                    height: 50, width: 50,
                                    margin: "5px",
                                    borderColor: currentIndex == (images.length + index) ? theme.palette?.primary?.main : "grey",
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    cursor: 'pointer',
                                    position: 'relative',
                                    backgroundColor: "#000000e0"
                                }}
                                onMouseEnter={() => handleChangeIndex(images.length + index)}
                                onClick={() => openLightBox(videoUrl)}
                            >
                                <img
                                    key={index}
                                    src={getThumbnail(videoUrl)}
                                    alt={`Video thumbnail ${index}`}
                                    style={{ width: '100%', objectFit: 'cover', height: 'auto', aspectRatio: 1 }}
                                />
                                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: "#fff" }}>
                                    <PlayCircleOutlineIcon fontSize="large" />
                                </Box>

                            </Box>
                        ))}
                    </Box>
                    {scrollPosition >= (previewContainerRef.current?.scrollWidth - previewContainerRef.current?.clientWidth) ?
                        null :
                        <Box sx={{ position: 'absolute', top: 0, right: 0, width: 40, height: '100%', zIndex: 101 }} >
                            <IconButton
                                onClick={handleScrollRight}
                                color='secondary'
                                sx={{ mt: 1.5, '&:hover': { backgroundColor: '#00000080' } }}
                                size={"small"}
                            >
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </Box>
                    }
                    {scrollPosition <= 0 ?
                        null :
                        <Box sx={{ position: 'absolute', top: 0, left: 0, width: 40, height: '100%', zIndex: 101 }} >
                            <IconButton
                                onClick={handleScrollLeft}
                                color='secondary'
                                sx={{ mt: 1.5, '&:hover': { backgroundColor: '#00000080' } }}
                                size={"small"}
                            >
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                        </Box>
                    }
                </Box>
                : null
            }
            <Lightbox
                open={showLighBox}
                onClose={closeLightBox}
                initialImage={[...images, ...videos].findIndex(item => item == lightBoxImage)}
                images={[...images?.map(item => ({ src: item })), ...videos?.map(item => ({ src: item, type: 'video' }))]}
            />
        </Box >
    );
};

export const VideoComponent = ({ url, videoStyle = {}, isPaused = false, showControls = false }) => {
    const [isYouTubeURL, setIsYouTubeURL] = useState(false);
    const [iframeApiLoaded, setIframeApiLoaded] = useState(false);
    const [youtubePlayer, setYoutubePlayer] = useState(null);
    const youtubeIframeRef = useRef(null);
    const videoElemRef = useRef(null);
    const videoContainerRef = useRef(null);
    //const isVisible = useIsVisible(videoContainerRef);

    const initYouTubePlayer = () => {
        if (iframeApiLoaded) {
            const iframeApi = youtubeIframeRef?.current?.contentWindow;
            console.log(iframeApi, window)
            const YT = window.YT;
            YT.ready(() => {
                let player_ = new YT.Player(youtubeIframeRef.current, { events: {} });
                setYoutubePlayer(player_);
            });
        }
    }

    useEffect(() => {
        if (iframeApiLoaded && isYouTubeURL) {
            initYouTubePlayer();
        }
    }, [iframeApiLoaded, isYouTubeURL])

    useEffect(() => {
        //console.log("Video isVisible", isPaused);
        try {
            if (isYouTubeURL && youtubePlayer) {
                if (isPaused) {
                    youtubePlayer.pauseVideo();
                } else {
                    //youtubePlayer.playVideo();
                }
            } else {
                if (videoElemRef.current) {
                    videoElemRef.current.pause();
                }
            }
        } catch (err) {
            console.log(err)
        }
    }, [isPaused])

    useEffect(() => {
        const isYouTubeURL_ = /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/.test(url);
        setIsYouTubeURL(isYouTubeURL_);
    }, [url])

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        script.async = true;
        script.onload = () => setIframeApiLoaded(true);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [])

    const generateYouTubeEmbedUrl = (youtubeLink) => {
        //const videoIdRegex = /[?&]v=([^#\&\?]+)/;
        //const videoIdRegex = /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const videoIdRegex = /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/; // added support for shorts video

        let match = youtubeLink.match(videoIdRegex);

        if (match && match[1]) {
            const videoId = match[1];
            return `https://www.youtube.com/embed/${videoId}?showinfo=0&iv_load_policy=3&autoplay=0&controls=${showControls ? 1 : 0}&loop=1&enablejsapi=1`;
        }
        return null;
    }
    return (
        <Box sx={{ width: "100%", height: '100%' }}>
            {isYouTubeURL ?
                <Box ref={videoContainerRef} style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <iframe
                        ref={youtubeIframeRef}
                        src={generateYouTubeEmbedUrl(url)}
                        frameBorder='0'
                        allow='autoplay; encrypted-media'
                        //title='video'
                        style={{ position: 'relative', zIndex: 10, width: '100%', objectFit: 'contain', height: 'auto', alignSelf: 'stretch', ...videoStyle }}
                    />
                </Box>
                :
                <Box ref={videoContainerRef} style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <video
                        controls
                        style={{ position: 'relative', zIndex: 10, width: '100%', objectFit: 'contain', height: 'auto', ...videoStyle }}
                        ref={videoElemRef}
                    >
                        <source src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            }
        </Box>
    )
}

export default ImageCarousel;